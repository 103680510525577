<template>
  <el-dialog
    top="45vh"
    :close-on-click-modal="false"
    class="import-add"
    :visible.sync="dialogVisible"
  >
    <div style="background-color: #fff;height: 54px;">
      <div class="close-icon" @click="dialogVisible = false">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="body">
      <i class="el-icon-success" style="color:#28CB97; font-size: 70px;"></i>
      <div>报名成功</div>

      <el-button style="margin: 30px 0px;" type="primary" @click="onSubmit">查看报名记录</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'TrainingCourse',
  components: {},
  data() {
    return {
      dialogVisible: false,
    }
  },
  mounted() {},
  methods: {
    show() {
      this.dialogVisible = true
    },
    onSubmit() {
      this.dialogVisible = false
      this.$emit('successSubmit')
    },
  },
}
</script>
<style lang="scss" scoped>
.import-add {
  ::v-deep.el-dialog {
    position: relative;

    width: 450px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .title {
      height: 54px;
      line-height: 54px;
      // border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: right;
      // border-top: 1px solid #c7c7c7;
    }
  }
}
</style>

<template>
  <div class="CollegeAdmin">
    <div class="box-card">
      <div slot="header" class="header">
        <span class="title">指定证书报名</span>
      </div>

      <div class="content">
        <div style="display: flex;">
          <div class="leftDiv"></div>
          <div class="leftTitle">基本信息</div>
        </div>
        <div class="titleContent">
          <div style="display: flex;">
            <div class="title" style="color: #FF7B33;">*</div>
            <div class="title">证书名称： {{ cerDetailItem.certName }}</div>
          </div>

          <div
            v-if="cerDetailItem.productAttributeCategoryId === 3"
            style="display: flex; align-items: center;"
          >
            <div class="title" style="color: #FF7B33;">*</div>
            <div class="title">证书等级:</div>
            <el-radio-group v-model="grade" style="margin-left: 15px;">
              <el-radio
                v-for="(item, index) in cerDetailItem.levelArrays"
                :key="index"
                :label="item"
                >{{ certLevelShowArray[index] }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>

        <div style="display: flex; margin-Top: 40px;">
          <div class="leftDiv"></div>
          <div class="leftTitle">培训班信息</div>
        </div>

        <div class="titleContent" style="display: flex;">
          <div class="title" style="color: #FF7B33;">*</div>
          <div class="title">培训班名称：</div>
          <el-button @click="onSelectClass">选择培训班</el-button>

          <ul v-if="courseItem.certPrivateTrainName != undefined" class="teachers">
            <li class="teacher">
              <span>{{ courseItem.certPrivateTrainName }}</span>
              <img src="@/assets/common/close_icon.png" class="close" @click="removeTeacher()" />
            </li>
          </ul>
        </div>

        <div style="display: flex; margin-top: 40px;">
          <div class="leftDiv"></div>
          <div class="leftTitle">添加报名人员</div>
        </div>

        <div class="tableHeader">
          <div style="display: flex;">
            <div class="headerTitle">当前人数</div>
            <div class="headerTitle" style="color: #FF7B33;">{{ pager.total }}</div>
            <div class="headerTitle">名</div>
            <el-input
              v-model="searchName"
              class="header-el-input"
              placeholder="请输入姓名"
            ></el-input>
            <el-button type="primary" @click="onSearch">查 询</el-button>
          </div>

          <div>
            <el-button style="margin-right: 20px;" type="primary" @click="onSelect"
              >从员工列表选择</el-button
            >
            <el-button style="margin-right: 20px;" @click="onDel">删除</el-button>
          </div>
        </div>

        <table-list
          class="tableList"
          :columns="columns(this)"
          :data="selectStaffList"
          :options="{ selection: true }"
          :pager="pager"
          @selectionChange="handleSelectionChange"
        ></table-list>
      </div>
      <div class="bottom-button">
        <el-button type="primary" @click="onSubmit">确认报名</el-button>
      </div>
    </div>

    <!-- <look-view ref="lookView"></look-view> -->
    <training-course ref="TrainingCourse" @selectionSubmit="selectionSubmit"></training-course>

    <staff-list ref="StaffList" @selectionStaffList="selectionStaffList"></staff-list>

    <success ref="Success" @successSubmit="successSubmit"></success>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工姓名',
    minWidth: '130px',
  },
  {
    prop: 'mobile',
    label: '手机号',
    minWidth: '150px',
  },
  {
    prop: 'cardNo',
    label: '身份证/护照',
    minWidth: '150px',
  },
  {
    prop: 'deptName',
    label: '所属部门',
    minWidth: '150px',
  },
  {
    prop: 'createTime',
    label: '添加时间',
    sortable: 'sortable',
    minWidth: '150px',
  },
]

import TableList from '@/components/TableList'
import TrainingCourse from './components/TrainingCourse/TrainingCourse.vue'
import Success from './components/Success/Success.vue'
import StaffList from './components/StaffList/StaffList.vue'
import { certificateDetail, classSubmit } from '@/api/certCenter'
import to from 'await-to'
export default {
  components: { TableList, TrainingCourse, StaffList, Success },
  data() {
    return {
      columns,
      certId: 0,
      cerDetailItem: {},
      certLevelShowArray: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      cerName: '企业人力资源证书',
      grade: 0,
      num: 100,
      searchName: '',
      courseItem: {}, //培训班信息
      selectStaffList: [], //保存报名人数 显示
      selectList: [], //添加报名人数
      deledata: [],
      certRelationTrainId: 0,
    }
  },
  mounted() {
    const { certId } = this.$route.params
    if (certId) {
      console.log(certId)
      this.certId = certId
      this.certificateDetail(certId)
    }
  },
  methods: {
    async certificateDetail(certId) {
      const [res, err] = await to(certificateDetail({ certId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.cerDetailItem = res.data

      this.certLevelShowArray = this.cerDetailItem.certLevelShow.split(',')
      console.log(this.certLevelShowArray)
      // let enclosure = this.form.enclosure.split(',')
    },
    onSelectClass() {
      console.log('选择培训班')
      this.$refs.TrainingCourse.show()
    },
    onSearch() {
      console.log('查询')
    },
    onSelect() {
      console.log('从员工列表选择')
      this.$refs.StaffList.show()
    },
    onDel() {
      console.log('删除')
      // this.selectStaffList.splice(index, 1)
      for (let i = 0; i < this.deledata.length; i++) {
        const index = this.selectStaffList.findIndex(
          item => item.userId === this.deledata[i].userId,
        )
        this.selectStaffList.splice(index, 1)
      }
      console.log(this.selectStaffList)
      this.pager.total = this.selectStaffList.length
    },
    // 分页
    // handleSizeChange(size) {
    //   this.pager.current = 1
    //   this.pager.size = size
    //   this.certificateList()
    // },
    // handleCurrentChange(current) {
    //   this.pager.current = current
    //   this.certificateList()
    // },
    handleSelectionChange(val) {
      this.deledata = val
    },
    onSubmit() {
      console.log('确认提交')
      console.log(this.grade)
      console.log(this.cerDetailItem)
      if (this.cerDetailItem.productAttributeCategoryId == 3 && this.grade == -1) {
        return this.$message.warning('请先选择证书等级')
      }

      if (!this.courseItem.certId) {
        return this.$message.warning('请先选择培训班')
      }

      if (!this.selectStaffList.length) return this.$message.warning('请先选择要报名的员工')

      this.$confirm('确定要报名吗？确定报名后将不可取消', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        this.onSetClassSubmit()
      })
    },
    selectionSubmit(params) {
      this.courseItem = params
    },
    selectionStaffList(params) {
      // this.selectStaffList.push(params)
      this.selectList = params
      let dataList = []
      dataList = this.selectStaffList.concat(this.selectList)
      console.log(dataList)
      this.selectStaffList = this.fn2(dataList)
      this.selectList = []
      this.pager.total = this.selectStaffList.length
    },
    fn2(arr) {
      const res = new Map()
      return arr.filter(arr => !res.has(arr.userId) && res.set(arr.userId, arr.userId))
    },
    successSubmit() {
      this.$router.push({
        path: '/certCenter/detailItem',
        query: {
          certRelationTrainId: this.certRelationTrainId,
        },
      })
    },
    removeTeacher() {
      this.courseItem = {}
    },
    async onSetClassSubmit() {
      let ids = []
      this.selectStaffList.map(v => ids.push(v.userId))
      let params = {
        certPrivateId: parseInt(this.certId),
        certLevel: this.grade,
        certPrivateTrainId: this.courseItem.certPrivateTrainId,
        certPrivateAppointAddReq: {
          userIds: ids,
        },
      }

      const [res, err] = await to(classSubmit(params))
      if (err) return this.$message.error(err.msg)
      // this.$message.success('添加成功')
      // this.$router.push('/certCenter/certManger')
      this.certRelationTrainId = res.data
      this.$refs.Success.show()
    },
  },
}
</script>
<style lang="scss" scoped>
.box-card {
  background: #ffffff;
  .header {
    height: 55px;
    border-bottom: 1px solid #e2e2e2;
    padding: 0 21px;
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 55px;
    }
  }

  .content {
    margin-top: 25px;
    margin-left: 20px;
    .leftDiv {
      width: 4px;
      height: 18px;
      background: #ff7b33;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-right: 10px;
    }

    .leftTitle {
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #4d4d4d;
      line-height: 20px;
    }

    .titleContent {
      margin-left: 10px;
      margin-top: 30px;
      .title {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        // background-color: #333333;
      }
      .teachers {
        position: relative;
        cursor: pointer;
        min-width: 50px;
        padding: 0 13px;
        height: 40px;
        opacity: 1;
        background: #ffffff;
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        // text-align: center;
        font-size: 14px;
        color: #333333;
        margin-right: 25px;
        margin-bottom: 10px;
        margin-left: 20px;
        .teacher {
          // box-sizing: border-box;
          // float: left;
          margin-top: 8px;
          .close {
            position: absolute;
            width: 14px;
            height: 14px;
            right: -5px;
            top: -5px;
          }
        }
      }
    }

    .tableHeader {
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-top: 30px;
      .headerTitle {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
      }
      .header-el-input {
        margin-left: 40px;
        width: 188px;
        margin-right: 25px;
      }
    }
  }

  .bottom-button {
    display: flex;
    justify-content: center;
  }
}
</style>

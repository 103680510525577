<template>
  <el-dialog
    top="15vh"
    :close-on-click-modal="false"
    class="import-add"
    :visible.sync="dialogVisible"
  >
    <div style="background-color: #F6F6F6;height: 54px;">
      <div class="title">选择培训班</div>
      <div class="close-icon" @click="dialogVisible = false">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="body">
      <table-list
        ref="singleTable"
        :loading="loading"
        :search-form="searchForm(this)"
        :data="dataList"
        :columns="columns(this)"
        :pager="pager"
        :options="{ singleCurrent: true }"
        @singleSelectChange="onSingleSelectChange"
        @search="onSearch"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </table-list>
    </div>

    <div class="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
// 搜索框配置
const searchForm = _this => [
  {
    label: '证书培训计划名称',
    prop: 'certPrivateTrainName',
  },
  {
    label: '适用证书',
    type: 'select',
    prop: 'certId',
    children: _this.certList.map(item => ({
      value: item.certId,
      label: item.certName,
    })),
  },
]

// 表格列配置
const columns = () => [
  {
    prop: 'certPrivateTrainName',
    minWidth: 150,
    label: '班级名称',
    twoLines: true,
  },
  {
    prop: 'certName',
    minWidth: 150,
    label: '适用证书',
    twoLines: true,
  },
  {
    prop: 'certLevelShow',
    minWidth: 150,
    label: '适用等级',
  },
]

import TableList from '@/components/TableList'
import { classCertQuery, certDropDownList } from '@/api/certCenter'
import to from 'await-to'
export default {
  name: 'TrainingCourse',
  components: {
    TableList,
  },
  data() {
    return {
      dialogVisible: false,

      loading: false,
      searchForm,
      columns,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      dataList: [],
      selectList: [],
      certList: [],
      currentRowItem: {},
    }
  },
  mounted() {
    this.getCertList()
    this.getList()
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    async getCertList() {
      const [res, err] = await to(certDropDownList({}))
      if (err) return this.$message.warning(err.msg)
      this.certList = res.data
    },
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        classCertQuery({
          current,
          size,
          ...this.queryParams,
          publishStatus: 1,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total

      for (let i = 0; i < res.data.list.length; i++) {
        let itemInfo = res.data.list[i]
        if (itemInfo.certLevel == null) {
          itemInfo.certLevelShow = '--'
        }
      }
      this.dataList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      console.log(queryParams)
      this.pager.current = 1
      this.queryParams = queryParams
      this.getList()
    },
    onSingleSelectChange(val) {
      this.currentRowItem = val
    },
    onSubmit() {
      this.dialogVisible = false
      this.$emit('selectionSubmit', this.currentRowItem)
    },

    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
.import-add {
  ::v-deep.el-dialog {
    position: relative;
    width: 800px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 25px;
    }
    .title {
      height: 54px;
      line-height: 54px;
      // border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: right;
      // border-top: 1px solid #c7c7c7;
    }
  }
}
</style>

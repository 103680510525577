<template>
  <el-dialog
    top="15vh"
    :close-on-click-modal="false"
    class="import-add"
    :visible.sync="dialogVisible"
  >
    <div style="background-color: #F6F6F6;height: 54px;">
      <div class="title">选择培训班</div>
      <div class="close-icon" @click="dialogVisible = false">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="body">
      <div class="tableHeader">
        <div>
          已选总人数：<span style="color: #FF7B33;">{{ selectList.length }}</span
          >人
        </div>
        <div>
          <el-input v-model="studentName" class="input" placeholder="请输入员工姓名"></el-input>
          <el-input v-model="cardNo" class="input" placeholder="请输入身份证号/护照"></el-input>

          <el-button style="margin-left: 20px;" type="primary" @click="onSearch()">搜 索</el-button>
        </div>
      </div>
      <table-list
        ref="tableList"
        :loading="loading"
        :data="dataList"
        :columns="columns(this)"
        :pager="pager"
        :options="{ selection: true, operatesWidth: 170, maxHeight: 540 }"
        @selectionChange="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </table-list>
    </div>

    <div class="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    minWidth: 150,
    label: '员工姓名',
  },
  {
    prop: 'mobile',
    minWidth: 150,
    label: '手机号码',
  },
  {
    prop: 'cardNo',
    minWidth: 150,
    label: '身份证号码/护照',
  },
]

import TableList from '@/components/TableList'
import { classStaffList } from '@/api/certCenter'
import to from 'await-to'
export default {
  name: 'TrainingCourse',
  components: {
    TableList,
  },
  data() {
    return {
      dialogVisible: false,

      loading: false,
      columns,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      dataList: [],
      selectList: [],

      studentName: '',
      cardNo: '',
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.$refs.tableList.cancelSelection()
    },
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        classStaffList({ current, size, studentName: this.studentName, cardNo: this.cardNo }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total

      for (let i = 0; i < res.data.list.length; i++) {
        let itemInfo = res.data.list[i]
        if (itemInfo.deptName == null || itemInfo.deptName == '') {
          itemInfo.deptName = '--'
        }
        if (itemInfo.cardNo == null || itemInfo.cardNo == '') {
          itemInfo.cardNo = '--'
        }
      }
      this.dataList = res.data.list
    },
    // 查询按钮
    onSearch() {
      this.pager.current = 1
      console.log(this.studentName)
      console.log(this.cardNo)
      this.getList()
    },
    handleSelectionChange(val) {
      this.selectList = val
    },
    onSubmit() {
      this.dialogVisible = false
      this.$emit('selectionStaffList', this.selectList)
      // this.$refs.tableList.cancelSelection();
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
.import-add {
  ::v-deep.el-dialog {
    position: relative;
    width: 900px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 25px;
      .tableHeader {
        display: flex;
        height: 55px;
        align-items: center;
        justify-content: space-between;

        .input {
          width: 180px;
          margin-left: 20px;
        }
      }
    }
    .title {
      height: 54px;
      line-height: 54px;
      // border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: right;
      // border-top: 1px solid #c7c7c7;
    }
  }
}
</style>
